.gallery-albums{
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.gallery-album{
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /*padding: 10px;*/
    width: 20%;
 /*   height: 18vh;*/
   /* width: 30%;*/
    /*min-height: 150px;*/
    font-weight: bold;
    cursor: pointer;
    display: block;
    margin: 5px;
    
}


.gallery-card:hover{
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
  
.gallery-album-container{
    overflow: hidden;
    /*height: 16vh;*/
    border-radius: 5px 5px 0px 0px;

}

.gallery-album img{
    width: 100%;
    display: block;
    margin: 0 auto;
}

.gallery-album-text{
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.gallery-component{
    width: 50%;
    margin: 0 auto;
}
/*
.image-gallery-content.fullscreen{
    top: 50%;
    transform: translateY(-50%);
}*/

.gallery-img{
    overflow: hidden;
    /*max-height: 55vh;*/
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    cursor: pointer;
}

.gallery-img:hover{
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}


.gallery-img img{
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}
@media (min-aspect-ratio: 3/1){
    .gallery-img{
        height: 35vh;
    }
}

@media (max-aspect-ratio: 3/1){
    .gallery-img{
        height: 20vh;
    }
}

@media (max-aspect-ratio: 16/9){
    .gallery-img{
        height: 20vh;
    }
}

@media (max-aspect-ratio: 4/3){
    .gallery-img{
        height: 15vh;
    }
}



@media only screen and (max-width: 1200px) {
    
    @media (min-aspect-ratio: 3/1){
        .gallery-img{
            height: 60vh;
        }
    }

    @media (max-aspect-ratio: 3/1){
        .gallery-img{
            height: 60vh;
        }
    }

    @media (max-aspect-ratio: 2/1){
        .gallery-img{
            height: 60vh;
        }
    }

    @media (max-aspect-ratio: 16/9){
        .gallery-img{
            height: 30vh;
        }
    }

    @media (max-aspect-ratio: 3/2){
        .gallery-img{
            height: 35vh;
        }
    }  

    @media (max-aspect-ratio: 1/1){
        .gallery-img{
            height: 25vh;
        }
    }

    

    @media (max-aspect-ratio: 9/10){
        .gallery-img{
            height: 35vh;
        }
    }

    @media (max-aspect-ratio: 4/5){
        .gallery-img{
            height: 25vh;
        }
    }

    @media (max-aspect-ratio: 80/100){
        .gallery-img{
            height: 30vh;
        }
    }

    @media (max-aspect-ratio: 76/100){
        .gallery-img{
            height: 30vh;
        }
    }

    @media (max-aspect-ratio: 2/3){
        .gallery-img{
            height: 20vh;
        }
    }

    @media (max-aspect-ratio: 6/10){
        .gallery-img{
            height: 30vh;
        }
    }

    
}