.root{
    font-family: "Roboto Condensed",sans-serif;
    font-size: large;
}

.page{
    min-height: 95vh;
    display: flex;
    
}

.top-image{
    min-height: 50vh;
    background-image: url('./assets/uvod.JPG');
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.top-image-text-wrapper{
    padding: 40px;
}

.top-text-h1{
    
    color: white;
    font-size: xxx-large;
}

.top-text-h3{
    
    color: white;
    font-size: xx-large;
}

.nav-link{
    font-size: x-large !important;
    font-weight: 600 !important;
}

.container{
    max-width: 100% !important;
    padding: 0px 20px 0px 20px !important;
}

.page-content{
    width: 54vw;
    margin: 0 auto;
    padding: 50px;
    font-size: large;
    margin-bottom: 30px;
}

.left-attachments, .right-attachments{
    width: 23vw;
}

.left-attachment{
    max-width: 300px; 
    text-align: center; 
    margin-top: 50px; 
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-left: 50px;
}

@media (max-width: 1500px){
    .page-content{
        width: 65vw;
        
    }

    .left-attachments img{
        min-width: 86px;
    }
}

@media (max-width: 1200px){
    .left-attachments{
        display: none;
        
    }
    
    .right-attachments{
        display: none;
    }

    .page-content{
        width: 100vw;
        padding: 20px;
    }
}

.page-content .subtitle{
    max-width: 80%;
    display: block;
    font-size: x-large;
    font-weight: bold;
}

.page-content-img{
    max-width: 80%;
    margin: 0 auto;
    display: block;
    max-height: 60vh;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 3px black
}

.page-content-items{
    padding: 10px 0px 10px 0px;
}

.page-content .text-content{
    color: grey;
    white-space: break-spaces;
   /* text-align: justify;*/
}

.footer-items{
    text-align: center;
}

.actual-item-ref{
    color: gray;
    text-decoration: none;
}

.actual-item-ref .card-title:hover{
    color: lightgray;
}

.actual-item{
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.actual-item img{
    min-width: 86px;
}

.ReactModal__Overlay--after-open{
    z-index: 4000 !important;
}

.ril-image-current{
    z-index: 1;
}

.ril__toolbar {
    z-index: 1;
}

.ril__navButtons{
    z-index: 1;
}

.custom-link{
    background-color: lightskyblue;
    color: white;
    font-weight: bold;
    padding: 1rem 1rem;
    margin: 0 auto;
    border-radius: 10px;
}

.custom-link:hover{
    color: black;
}

.custom-link-file{
    background-color: white;
    color: #0d6efd;
    font-weight: bold;
    padding: 1rem 1rem;
    margin: 0 auto;
    border-radius: 10px;
    display: inline-block;
    box-shadow: 0px 0px 3px #0d6efd;
}

.custom-link-file:hover{
    background-color: #0d6efd;
    color: white;
}

.custom-element-wrapper{
    text-align: center;
}

.custom-list{
    color: grey;
    margin-top: 0;
    margin-bottom: 0;
}

.custom-list li{
    list-style: circle;
}

.custom-pair{
    color: grey;
}

.custom-pair-key{
    font-weight: bold;
}

.custom-pair-value{
    padding-left: 20px;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
}

.page-content-items-map{
    display: flex;
    justify-content: center;
    touch-action: none;
    
}

.page-content-items-map .smap{
    display: flex;
    justify-content: center;
    width: 70% !important;
    height: 50vh !important;
    border-radius: 10px;
    box-shadow: 0px 0px 3px grey;
}

.contact-form{
    border-radius: 10px;
    box-shadow: 0px 0px 3px grey;
    padding: 30px;
}

.contact-form button{
    margin: 0px !important;
}

@media (max-width: 750px){
    .top-image{
        background-image: url('./assets/uvod.JPG');
        background-repeat: no-repeat;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .top-text-h1{
    
        color: white;
        font-size: xx-large;
    }
    
    .top-text-h3{
        
        color: white;
        font-size: x-large;
    }
}

@media (min-width: 1200px){
    .actual-item{
        display: none;
    }
}

@media (max-width: 1199px){
    .galleries-admin-panel-btn-column{
        padding-top: 10px;
    }

    .page-content-img{
        max-width: 100%;
        max-height: none;
    }

    .page-content-items-map .smap{
        display: flex;
        justify-content: center;
        width: 100% !important;
        height: 80vh !important;
    }
}

@media (max-width: 572px){
    .page-content-menu-right{
        text-align: left !important;
        
    }

    .page-content-menu-right button:nth-child(1){
        margin-left: 0px !important;
    }

    .page-content-items-map .smap{
        display: flex;
        justify-content: center;
        width: 100% !important;
        height: 50vh !important;
    }
}

@media (max-width: 485px){

    .page-content-menu-right button{
        margin-left: 0px !important;
    }
}
