.page-row-input{
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 25px;
    font-size: larger;
    width: 200px;
    padding: 1px 5px 1px 5px ;
}

.editor-page{
    padding-bottom: 10px;
}

.editor-page-row{
    display: inline-block;
    font-size: large;
    margin-right: 20px;
}

.editor-page-row div{
    font-weight: bold;
    display: inline;
}

.page-row-text{
    margin-right: 10px;
}

.page-row-checkbox{
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 20px;
    height: 20px;
}

.editor-page button{
    float: right;
    margin-left: 10px;
}

.add-new-page-button{
    float: right;
    margin-bottom: 30px;
}

.add-new-page{
    display: flow-root;
}

.page-content{
    display: flow-root;
    padding-bottom: 10px;
}

.page-content-list{
    margin-top: 20px;
    width: 100%;
}

.page-content button{
    margin-left: 10px;
}

.page-content-row-left{
    display: inline-block;
    width: 50%;
}

.page-content-row-right{
    display: inline-flex;
    float: right;
}

.page-content-row-public{
    display: inline-block;
    font-size: large;
    margin-right: 20px;
}

.page-content-public-text{
    font-weight: bold;
    display: inline;
    font-size: large;
    margin-right: 10px;
}

.page-content-checkbox{
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 20px;
    height: 20px;
}

.text-content{
    white-space: pre-line;
}

.add-new-content-panel{
    display: flow-root;
}


.select-wrapper{
    display: flex;
}

.select-input{
  width: 150px;
}


.left-panel{
    display: inline-block;
}

.right-panel{
    display: inline-block;
    float: right;
}
/*
.page-content-button{
    float: right;
}
/*/
.select-label{
    padding-top: 10px;
    margin-right: 10px;
}

.page-content-row-left input{
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 25px;
    font-size: larger;
    width: 200px;
    padding: 1px 5px 1px 5px;
}

.page-content-row-left textarea{
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 25px;
    font-size: larger;
    width: 100%;
    padding: 1px 5px 1px 5px;
    height: auto;
}

.page-contents, .page-content-row{
    width: 100%;
}

.page-content-row-text{
    font-weight: bold;
}

.page-content-row{
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 10px 10px 0px 10px;
    margin-bottom: 10px;
    background-color: white;
}

