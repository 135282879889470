.footer-items{
    background-color: rgba(35, 38, 41, 1.0);
    text-align:center;
    width:100%;
    padding-top: 10px;
    padding-bottom: 10px;
}
  
.footer-items img{
    max-height: 70px;
    margin: 0px 20px 0px 20px;
    cursor: pointer;
}

.footer-items div{
    display: contents;
}
  
.footer-items img:hover{
    opacity: 0.5;
}

@media (max-width: 400px){
    .footer-items div{
       display: block;
    }

    .footer-items img{
        max-height: 70px;
        margin: 20px 0px 20px 0px;
        cursor: pointer;
    }
}